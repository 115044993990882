<template>
  <div class="rounded p-5 text-center flex-grow-1">
    <i class="fe fe-inbox font-size-80 mb-3 d-block" />
    <div class="text-dark font-weight-bold font-size-36">Free Plan</div>
    <div class="text-dark font-weight-bold font-size-48 mb-3">
      $0 <span class="align-text-top font-size-28 text-gray-6">/mo</span>
    </div>
    <ul class="list-unstyled font-size-18 mb-5">
      <li>10GB of Bandwidth</li>
      <li>200MB Max File Size</li>
      <li>2GHZ CPU</li>
      <li>256MB Memory</li>
      <li>1 GB Storage</li>
    </ul>
    <a class="btn btn-primary width-100" href="javascript: void(0);">Get Access</a>
  </div>
</template>
<script>
export default {
  name: 'VbList21',
}
</script>
